import {
    mapMutations
  } from 'vuex'
export const DATA_SETTERS= {
    methods: {
        ...mapMutations([
            'setProductItems','setDrNumber','setDrDate','setCustomers','setLastUpdate', 'setFulls', 'setEmpties',
            'setSales', 'setUploadedDate', 'setBookings'
        ]),
        SET_FULLS(){
            localStorage.fulls?this.setFulls(JSON.parse(localStorage.fulls)):this.setFulls([])
        },
        SET_EMPTIES(){
            localStorage.empties?this.setEmpties(JSON.parse(localStorage.empties)):this.setEmpties([])
        },
        SET_LAST_UPDATE(){
            localStorage.last_update?this.setLastUpdate(localStorage.last_update):this.setLastUpdate('')
        },
        SET_CUSTOMERS(){
            localStorage.customers?this.setCustomers(JSON.parse(localStorage.customers)):this.setCustomers([])
        },
        SET_PRODUCT_ITEMS() {
            localStorage.product_items?this.setProductItems(JSON.parse(localStorage.product_items)):this.setProductItems([])
        },
        SET_DRNUMBER() {
            localStorage.dr_number?this.setDrNumber(localStorage.dr_number):this.setDrNumber('')
        },
        SET_DRDATE() {
            localStorage.dr_date?this.setDrDate(localStorage.dr_date):this.setDrDate('')
        },
        SET_UPLOADED_DATE() {
            localStorage.uploaded_date?this.setUploadedDate(localStorage.uploaded_date):this.setUploadedDate('')
        },
        SET_SALES(){
            localStorage.sales?this.setSales(JSON.parse(localStorage.sales)):this.setSales([])
        },
        SET_BOOKINGS(){
            localStorage.bookings?this.setBookings(JSON.parse(localStorage.bookings)):this.setBookings([])
        },
        CLEAR_OFFLINE(){
            localStorage.sales_back = localStorage.sales
            localStorage.dr_date_back = localStorage.dr_date
            localStorage.dr_number_back = localStorage.dr_number
            localStorage.product_items_back = localStorage.product_items
            delete localStorage.sales
            delete localStorage.dr_date
            delete localStorage.dr_number
            delete localStorage.product_items
            this.SET_SALES()
            this.SET_DRDATE()
            this.SET_DRNUMBER()
            this.SET_PRODUCT_ITEMS()
        },
        SAVE_BOOKING(bookings){
            if(localStorage.bookings) {
                var current_bookings = JSON.parse(localStorage.bookings)
                current_bookings.push(bookings)
                localStorage.bookings = JSON.stringify(current_bookings)
            } else {
                var new_bookings = []
                new_bookings.push(bookings)
                localStorage.bookings = JSON.stringify(new_bookings)
            }
        },
        SAVE_SALES(sales){
            if(localStorage.sales) {
                var current_sales = JSON.parse(localStorage.sales)
                current_sales.push(sales)
                localStorage.sales = JSON.stringify(current_sales)
            } else {
                var new_sales = []
                new_sales.push(sales)
                localStorage.sales = JSON.stringify(new_sales)
            }
            window.PrintInvoice(sales, this.$moment().format(this.$date_format))
            this.SET_SALES()
        },
        UPDATE_SOLD_COUNT(product, action){
            var current_items = JSON.parse(localStorage.product_items)
            var items = current_items.reduce((res,item)=>{
                if(item.product_code == product.product_code) {
                    if(action == "add") {
                        item.sold  += this.$IsNum(product.quantity)
                    } else if(action == "remove") {
                        if(item.sold > 0) {
                            item.sold  -= this.$IsNum(product.quantity)
                        }
                       
                    }
                    
                }
                console.log("orbith push:")
                res.push(item)
                return res
            },[])
            localStorage.product_items = JSON.stringify(items)
           // console.log(localStorage.product_items)
            this.SET_PRODUCT_ITEMS()

        }
       
    } ///eof methods
}