
<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen>
    <div style="background: #000; overflow:hidden;height:100%;width:100%">
     <iframe src="https://test.game-spot.net/?v=7.23123123.0-231312-prod" frameborder="0" @load="loadstatus()"   style="overflow:hidden;height:100%;width:100%" height="100%" width="100%"></iframe>
     
    </div>
  </v-dialog>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
    data: () => ({
        animsrc: 'https://test.game-spot.net/?v=7.1.0-prod',
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    }
  },
  watch: {
      show(v){
          if(v) {
              console.log(screen.width)
          }
      }
  },
  computed: {
      style() {
          return "overflow:hidden;height:"+screen.height + "px;width:" +screen.width+"px;"
      }
  },
  methods: {
      ...mapMutations([ 'setOpenMachine']),
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    loadstatus() {
      console.log(sessionStorage.machine)
      if(sessionStorage.machine=="init") {
        
          setTimeout(()=>{
              sessionStorage.machine='loaded'
            this.setOpenMachine(false)
          }, 7000)
          
      }
    }
  }
}
</script>
