<template>
<v-row >
  <v-col cols="12">
     <v-card>
              <v-card-text>
                 <p>
                    Your version: {{current_version}} 
                  </p>
                    <template >
                         <v-alert
                            dense
                            border="left"
                            type="error"
                          >
                           Application update required!
                          </v-alert>

                          <div class="text-center">
                            Latest version: {{system.version}}<br/>
                            Released Date: {{system.last_update}}<br/>
                          <p>
                           <strong> Please contact administrator!</strong>
                          </p>
                          </div>
                           
                    </template>
              </v-card-text>
            </v-card>
  </v-col>
</v-row>
</template>

<script>
export default {
  props: {
    system: {
      type: Object,
      default:()=>{}
    }
  },
  computed:{
     current_version(){
          return localStorage._kake_system_version
        },
  }
}
</script>