<template>
     <v-card :class="color" dark>
              <v-card-text style="height: 80px;" class="text-center">
                  <p>
                  <small class="font-weight-thin" style="border-bottom: 1px solid #F96332;">{{label}}</small><br />
                  <span class="lg-font">{{data}}</span>
                  </p>
                </v-card-text>
            </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "secondary"
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    data: {
      type: String,
      default: ''
    },
    sup: {
      type: String,
      default: ''
    }
  }
}
</script>