<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="420">
    <v-card>
        <v-toolbar dense dark color="secondary">SELECT CUSTOMER<v-spacer/><v-icon @click="emitToParent('cancel')">mdi-close</v-icon> </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-3"
                v-model="valid"
                lazy-validation
              >
                        <v-autocomplete
                            prepend-icon="mdi-storefront"
                            v-model="customer"
                            label="Select Cutomer"
                            :rules="textRules"
                            :items="$store.state.customers"
                            return-object
                            item-text="trade_name"
                            dense
                            outlined
                            required
                            >
                    <template v-slot:item="data">
                         <span> ({{ data.item.area_code }}) </span> - {{ data.item.trade_name }}
                  </template>
                </v-autocomplete>

                 <v-text-field
                    v-model="customer.area_code"
                    label="Area"
                    prepend-icon="mdi-map-marker-radius"
                    dense
                    filled
                    readonly
                ></v-text-field>
                <v-text-field
                    v-model="customer.address"
                    label="Address"
                    prepend-icon="mdi-map"
                    dense
                    filled
                    readonly
                ></v-text-field>
                <v-text-field
                    v-model="customer.contact_number"
                    label="Phone No."
                    prepend-icon="mdi-cellphone-basic"
                    dense
                    filled
                    readonly
                ></v-text-field>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small  text @click="emitToParent('cancel')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success" @click="validate()">ADD</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    }
  },
  data: () => ({
    valid:true,
    customer:{},
      textRules: [
        v => !!v || 'Field is required'
      ],
      sale:{}
  }),
  computed: {
  },
  watch: {
    
  },
  methods: {
     ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setKilosToDistribute']),
     validate () {
        if (this.$refs.form.validate()) {
           this.$emit('DialogEvent', this.customer)
        }
    },
    emitToParent () {
       this.$emit('DialogEvent', 'close')
    }
  }
}
</script>
