<template>
  <div class="small">
          <line-chart :height="300" :data="data" :labels="labels" style="background: #19365A; padding: 7px; color: #fff;" dark></line-chart>
  </div>
</template>

<script>
  import LineChart from '@/plugins/LineChart.js'

  export default {
      components: {
        LineChart
      },
       data: () => ({
         labels: [],
         data:[],
      }),
      props: {
        rawdata: {
          type: Array,
          default: function(){ return []}
        }
      },
    created() {
       this.exportData()
    },
    methods: {
      exportData() {
        this.labels = []
        this.data = []
        for (const item of this.rawdata) {
              this.labels.push(item.log_date)
              this.data.push(item.gross_sales)
          }
      }
    }
  }
</script>

<style>
  .small {
    height: 300px;
  }
</style>