<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="300">
    <v-card color="secondary" dark>
        <v-card-text class="pa-4 subtitle-1">
            {{text}}
        </v-card-text>
        <v-card-actions>
                
            <v-btn   color="success"  rounded block  @click="emitToParent('ok')">OK</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    okbtn:{
         type: String,
        default: ""
    },
    cancelbtn:{
         type: String,
        default: ""
    },
    text: {
        type: String,
        default: ""
    }
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    }
  }
}
</script>
