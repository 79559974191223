<template>
    <v-row no-gutters>
            <v-col md="12" class="\mb-5">
                    <v-row no-gutters>
                        <v-divider/> <span class="title">PROFIT SUMMARY</span><v-divider/>
                    </v-row>
            </v-col>
           <v-col md="8">
               <chart-income :rawdata="SUMMARYDATA"/>
           </v-col>
           <v-col md="4">
               <chart-pie :rawdata="PIESUMMARYDATA"/>
           </v-col>
          <v-col md="12">
             <v-data-table
              :headers="headers"
              dense
              class="va-table pt-5"
              :items="SUMMARYDATA"
              :items-per-page="10000"
              disable-sort
              hide-default-footer
              >
               <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item,index) in items" :key="index">
                              <td>
                                  {{ item.month_year }}
                              </td>
                              <td class="text-right">
                                  {{ $money(item.kilos) }}
                              </td>
                              <td class="text-right">
                                  {{ $money(item.ticket) }}
                              </td>
                              <td class="text-right">
                                  {{ $money(item.parking) }}
                              </td>
                              <td class="text-right">
                                  {{ $money(item.labor) }}
                              </td>
                              <td class="text-right">
                                  {{ $money(item.service_charge) }}
                              </td>
                               <td class="text-right">
                                  {{ $money(item.trucking_fee) }}
                              </td>
                              <td class="text-right">
                                  {{ $money(item.commission) }}
                              </td>
                             
                               <td class="text-right">
                                  {{ $money($IsNum(item.kilos) + $IsNum(item.ticket) + $IsNum(item.parking) +$IsNum(item.labor) +$IsNum(item.service_charge)+$IsNum(item.commission) +$IsNum(item.trucking_fee)) }}
                              </td>
                      </tr>
                  </tbody>
                  <tfoot >
                <tr class="font-weight-bold" >          
                    <td class="text-right" style="border-top: 1px solid #212121;">TOTAL</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">{{ $money(KILOS) }}</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">{{ $money(TICKET) }}</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">{{ $money(PARKING) }}</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">{{ $money(LABOR) }}</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">{{ $money(SC) }}</td>
                     <td class="text-right" style="border-top: 1px solid #212121;">{{ $money(TF) }}</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">{{ $money(COMMISSION) }}</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">
                       {{ $money(KILOS + TICKET +PARKING +SC +LABOR+COMMISSION+TF) }}
                    </td>
                </tr>
                
            </tfoot>
               </template>
             </v-data-table>
          </v-col>
        </v-row>
</template>

<script>
export default {
//   props: {
//     summarydata: {
//         type: String,
//         default: ""
//     }
//   },
  data: () => ({
      headers:[
          {text: 'Year/Month'},
          {text: 'Kilos', align:'right'},
          {text: 'Ticket', align:'right'},
          {text: 'Parking', align:'right'},
          {text: 'Labor', align:'right'},
           {text: 'SC', align:'right'},
          {text: 'TF', align:'right'},
          {text: 'Commision', align:'right'},
          {text: 'Total', align:'right'}
      ]
    }),
computed:{
      KILOS(){
          var arr = this.SUMMARYDATA
           let total = arr.reduce((a, o) => a += this.$IsNum(o.kilos), 0)
           return total
      },
       TICKET(){
          var arr = this.SUMMARYDATA
           let total = arr.reduce((a, o) => a += this.$IsNum(o.ticket), 0)
           return total
      },
      PARKING(){
          var arr = this.SUMMARYDATA
           let total = arr.reduce((a, o) => a += this.$IsNum(o.parking), 0)
           return total
      },
      LABOR(){
          var arr = this.SUMMARYDATA
           let total = arr.reduce((a, o) => a += this.$IsNum(o.labor), 0)
           return total
      },
      SC(){
          var arr = this.SUMMARYDATA
           let total = arr.reduce((a, o) => a += this.$IsNum(o.service_charge), 0)
           return total
      },
       TF(){
          var arr = this.SUMMARYDATA
           let total = arr.reduce((a, o) => a += this.$IsNum(o.trucking_fee), 0)
           return total
      },
      COMMISSION(){
          var arr = this.SUMMARYDATA
           let total = arr.reduce((a, o) => a += this.$IsNum(o.commission), 0)
           return total
      },
      PIESUMMARYDATA(){
          let piedata = {
              labels: ['Parking', 'Labor', 'SC', 'TF', 'Comm'],
              data: [this.PARKING, this.LABOR, this.SC, this.TF, this.COMMISSION]
          }
          return piedata
      },
      SUMMARYDATA(){
         return this.$store.state.income_source_summary
      }
    },
  methods: {
  }
}
</script>
