export const set = property => (state, payload) => (state[property] = payload)

export const toggle = property => state => (state[property] = !state[property])

export const save = property => (state, payload) => {
     state[property] = payload
     localStorage.product_items = JSON.stringify(payload)
  } 

export default {
  setDrawer: set('drawer'),
  setResponsive: set('responsive'),
  setLoggedIn: set('is_logged'),
  setAlert: set('show_alert'),
  setAlertColor: set('alert_color'),
  setOverlayLoading: set('overlay_loading'),
  setOverlayLoadingText: set('overlay_loading_text'),
  setAlertText: set('alert_text'),
  setTickets: set('tickets'),
  setUsers: set('users'),
  setIsOnline: set('is_online'),
  setProductItems:  set("product_items"),
  setAccount: set("account"),
  setResults:  set("results"),
  setLastUpdate: set("last_update"),
  setBalance: set('balance'),
  setSetOpen2dDraws: set('open_2d_draws'),
  setOpenMachine: set('open_machine'),
  setWatchDraw: set("watchdraw"),
  setBettorName: set("bettor_name"),
  setSetOpen3dDraws:set("open_3d_draws"),
  setSetOpenLiveDraws:set("open_live_draws"),
  setAccountSummary: set('account_summary'),
  setLiveDrawStatus:set("live_draw_status"),
  setRequiredUpdate:set("required_update"),
  setPick:set("pick")
}
