<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
      <v-toolbar dense dark color="success">
        Ticket Successful
       </v-toolbar>
        <v-card-text class="pa-4">
            GAME: {{GAMENAME(ticket.game)}} 
            <br/>DRAW: {{ticket.draw_date}}
            <br/>TICKET #: {{ticket.id}}
           
             <br/>TICKET DATE: {{$moment(ticket.transaction_date).format("MMM DD, YYYY hh:mm:ss a")}}
            <br/>
            <table class="va-table print_table3 mt-2 text-left " cellspacing="0">
                      <tr v-for="(item, i) in ticket.ResponseLines" :key="i">
                        <template v-if="ticket.game == '2D' || ticket.game == 'STL'">
                          <td class="font-weight-bold"> 
                            <template v-if="BETTYPE('P2', item.line_type) > -1">
                                {{item.num1}} - {{item.num2}}
                              </template>
                              <template  v-if="BETTYPE('P3', item.line_type) > -1">
                                {{item.num1}} - {{item.num2}} - {{item.num3}}
                              </template>
                              <template  v-if="BETTYPE('P4', item.line_type) > -1">
                                {{item.num1}} - {{item.num2}} - {{item.num3}} - {{item.num4}}
                              </template>
                              <template  v-if="BETTYPE('P5', item.line_type) > -1">
                                {{item.num1}} - {{item.num2}} - {{item.num3}} - {{item.num4}} - {{item.num5}}
                              </template>
                             <template v-if="item.soldnum.length > 0">
                              <br />
                              <i class="text-warning">Sold Out: 
                                <span v-for="(sold, c) in item.soldnum" :key="c" >
                                  {{sold}}
                                </span>,
                              </i>
                            </template>
                            <div>
                              <template v-if="BETTYPE('RBL', item.line_type) > -1 || BETTYPE('STD', item.line_type) > -1">
                                <v-chip label> {{$money(item.total_amount)}}</v-chip>
                                </template>
                                <template v-else>
                                <v-chip label > T: {{$money(item.tombok)}}</v-chip>
                                <v-chip  label class="ml-2">S: {{$money(item.sahud)}}</v-chip> 
                              </template>
                            </div>
                          </td>
                        </template>
                       
                        <td class="text-center"> 
                          {{item.line_type}}
                          </td>
                          <td class="text-right">
                               <span class="text-success font-weight-bold">{{$money(item.total_amount)}}</span> <v-chip x-small color="success">Sent</v-chip>
                                <template v-if="$IsNum(item.line_amount)>$IsNum(item.total_amount)">
                                    <br />
                                    <span class="text-error"> {{$money(item.line_amount - item.total_amount)}}</span> <v-chip color="error"  x-small>Sold Out</v-chip>
                                </template>
                          </td>
                      </tr>
                  </table>
                   <v-row no-gutters class="text-primary">
                    <!-- <p class="text-info">No. of draws: {{ticket.drawnum}}</p> -->
                    <v-spacer/>
                    <h4>TOTAL:  {{$money(ticket.amount)}}</h4>
                  </v-row>
                   <v-row no-gutters class="text-primary">
                        <div style="width: 100px; height:100px; margin: auto;">
                             <div id="qrcode_container" class="text-center"></div>
                     </div>
                   </v-row>
        </v-card-text>
        <v-card-actions>
                <v-btn color="success" block  @click="emitToParent('done')">Done</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
    data: () => ({
    }),
    components: {
   // VueQrcode,
  },
  props: {
    show: {
        type: Boolean,
        default: false
    },
    ticket: {
        type: Object,
        default: ()=> { return {} }
    }
  },
  watch:{
     show(v){
          if(v) {
        setTimeout(()=> {
            var qrcode = new window.QRCode(document.getElementById("qrcode_container"), {
            width : 100,
            height : 100
        });
        qrcode.makeCode(this.ticket.id + ':' + this.ticket.code);
     
        }, 100)
           
      } else {
           setTimeout(()=>{
            document.getElementById("qrcode_container").innerHTML = ""
        }, 500)
       
      }
   }
  },
  computed: {
    qrval() {
      return this.ticket.id+":"+this.ticket.code
    },
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    clear() {
        this.ticket = {}
        this.lines = []
    }
  }
}
</script>
