<template>

  <v-dialog
    v-model="show"
    persistent
    >
    <v-card>
       <v-toolbar dense dark color="secondary">
           Winning Ticket
       </v-toolbar>
        <v-card-text class="pa-4">
            GAME: {{ticket.game=="2DLOCAL"?"2D LIVE":ticket.game}}
            <br/>Draw: {{ticket.draw_date}}
            <h4>Won:  {{$money(ticket.amount_payout)}}</h4>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning"  @click="emitToParent('cancel')">Close</v-btn>
            <v-spacer></v-spacer>
            <v-btn   color="success" :loading="loading" @click="claim()">Claim</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
      loading: false
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    ticket: {
        type: Object,
        default: ()=> { return {} }
    }
  },
  watch:{
    // show(v){
    //   if(v) {
    //     this.clear()
    //   }
    // }
  },
  computed: {
    TOTAL() {
           return this.lines.reduce((res,item)=>res += this.$IsNum(item.line_amount), 0)
      }
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    clear() {
    },
    claim(){
      this.loading = true
         this.$http.post("claim/winning_ticket", {ticket_id: this.ticket.ticket_id}).then(response => {
                this.loading = false
                if(response.data.status) {
                  this.VA_ALERT("success", response.data.message)
                } else {
                  this.VA_ALERT("error", response.data.message)
                }
              //  this.emitToParent("cancel")
               }).catch(e => {
                console.log(e.data)
            })
    }
  }
}
</script>
