<template>
     <v-card :class="color" dark>
              <v-card-text style="height: 100px;" class="text-center">
                {{label}}
                  <div class="text-h4 text-gold text-shadow">{{data}}</div>
                </v-card-text>
            </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "secondary"
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    data: {
      type: String,
      default: ''
    },
    sup: {
      type: String,
      default: ''
    }
  }
}
</script>