<template>
     <v-card :class="color" dark>
              <v-card-text style="height: 110px;" class="text-center">
                {{label}}
                 <div>
                      <v-progress-circular
                          :size="50"
                          :width="4"
                          color="gold"
                          class="ma-2"
                          indeterminate
                        ></v-progress-circular>
                          <v-progress-circular
                          :size="50"
                          :width="4"
                            class="ma-2"
                          color="gold"
                          indeterminate
                        ></v-progress-circular>
                 </div>
                </v-card-text>
            </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "secondary"
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    data: {
      type: String,
      default: ''
    },
    sup: {
      type: String,
      default: ''
    }
  }
}
</script>