<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
        <v-card-text class="pa-4 subtitle-1">
          
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small text rounded  @click="text ='close', emitToParent()">close</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  rounded  @click="text ='close', emitToParent()"> <v-icon left >mdi-plus</v-icon> ADD</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
    
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    }
  },
  watch:{
      show(val) {
          if(!val){this.text=''}
      }
  },
  methods: {
    
    emitToParent () {
      this.$emit('DialogEvent', "")
    }
  }
}
</script>
