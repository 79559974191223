<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
        <v-toolbar dense>
           Sale Print Preview
            <v-spacer/>
                <v-icon class="ma-1" @click="print()" color="success">mdi-printer</v-icon> 
                <v-icon class="ma-1" @click="emitToParent('close')" color="warning">mdi-close-circle</v-icon>
        </v-toolbar>
        <p></p>
        <div id="PrintMe">
            <div class="print-size">
                 <p class="text-center"><strong>KCY ROYAL CROWNE BEVERAGE CORPORATION</strong>
                    <br />Purok Caimito, Mankilam, City of Tagum (Capital), Davao Del Norte<br />
                        VAT Reg. TIN: 009-991-779-00
                </p>
                <br />
                <p>
                    <span style="float: right;"> Date: {{$moment(sales.delivered_date).format("YYYY-MMM-DD")}}</span>
                    <strong>SALES INVOICE: {{sales.invoice}}</strong><br />
                    Customer: {{customer.trade_name}}<br />
                    Address: {{customer.address}}
                </p>
                <table class="print_table" >
                    <tr>
                        <td colspan="4" style="background: #e5e5e5;">FULL</td>
                    </tr>
                    <tr>
                        <td>SKU</td>
                        <td>QTY</td>
                        <td class="text-right">UNIT PRICE</td>
                        <td class="text-right">AMOUNT</td>
                    </tr>
                    <tr v-for="(item, index) in fulls" :key="index+'-f'">
                        <td>{{item.product_code}}</td>
                        <td>{{item.quantity}}</td>
                        <td class="text-right">{{$money(item.unit_price)}}</td>
                        <td class="text-right">{{$money(item.unit_price * item.quantity)}}</td> 
                    </tr>
                    <tr>
                        <td colspan="4" class="text-right">SUB-TOTAL:  {{$money(GROSSTOTAL)}}</td>
                    </tr>


                    <tr>
                        <td colspan="4" style="background: #e5e5e5;">DEPOSIT</td>
                    </tr>
                    <tr>
                        <td>SKU</td>
                        <td>QTY</td>
                        <td class="text-right">UNIT PRICE</td>
                        <td class="text-right">AMOUNT</td>
                    </tr>
                    <tr v-for="(item, index) in DEPOSITS" :key="index+'-d'">
                        <td>{{item.product_code}}</td>
                        <td>{{item.deposit}}</td>
                        <td class="text-right">{{$money(item.unit_price)}}</td>
                        <td class="text-right">{{$money(item.unit_price * $money(item.deposit))}}</td> 
                    </tr>
                    <tr>
                        <td colspan="4" class="text-right">SUB-TOTAL: {{$money(DEPOSITTOTAL)}}</td>
                    </tr>
                    <tr>
                        <td colspan="4" style="background: #e5e5e5;">REFUNDS</td>
                    </tr>
                    <tr>
                        <td>SKU</td>
                        <td>QTY</td>
                        <td class="text-right">UNIT PRICE</td>
                        <td class="text-right">AMOUNT</td>
                    </tr>
                    <tr v-for="(item, index) in REFUNDS" :key="index+'-r'">
                        <td>{{item.product_code}}</td>
                        <td>{{item.refund}}</td>
                        <td class="text-right">{{$money(item.unit_price)}}</td>
                        <td class="text-right">{{$money(item.unit_price * $money(item.refund))}}</td> 
                    </tr>
                    <tr>
                        <td colspan="4" class="text-right">SUB-TOTAL: {{$money(REFUNDTOTAL)}}</td>
                    </tr>

                     <tr>
                        <th colspan="4" class="text-right">TOTAL: {{$money(GRANDTOTAL)}}</th>
                    </tr>
                </table>
                <br />
                <div style="height: 100px; margin: 100px 0">
                <div style="width: 70%; float: left;">
                        <strong>NO.: {{sales.invoice}}</strong>
                </div>
                <div style="width: 30%; float: left;" class="text-center">
                        ___________________________<br />
                        Authorized Signature
                </div>
                </div>
            </div>
        </div>
           
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    customer: {
          type: Object,
        default:()=>{ }
    },
    sales: {
        type: Object,
        default:()=>{}
    },
    empties: {
        type: Array,
        default:()=>{ return [] }
    },
    fulls: {
        type: Array,
        default:()=>{ return [] }
    },
    redeemeds: {
        type: Array,
        default:()=>{ return [] }
    },
    borroweds: {
        type: Array,
        default:()=>{ return [] }
    },
    summary:{
      type: Array,
        default:()=>{ return [] }
    }
  },
  data: () => ({
   
  }),
  computed: {
     GROSSTOTAL(){
          var total =  this.summary.reduce((res, item)=>{
              if(item.total == "gross_total") {
                  res=item.amount
              }
              return res
          },0)
          return total
     },
     GRANDTOTAL(){
          var total =  this.summary.reduce((res, item)=>{
              if(item.total == "grand_total") {
                  res=item.amount
              }
              return res
          },0)
          return total
     },
     REFUNDS(){
          var arr =  this.empties.reduce((res, item)=>{ 
             if(this.$IsNum(item.refund) != 0){
                 res.push(item)
             }
             return res
        },[])
        return arr
     },
      DEPOSITS() {
         var arr =  this.empties.reduce((res, item)=>{ 
             if(this.$IsNum(item.deposit) != 0){
                 res.push(item)
             }
             return res
        },[])
        return arr
     }, 
     DEPOSITTOTAL() {
         var total =  this.empties.reduce((res, item)=>{ 
             res += this.$IsNum(item.deposit)  * this.$IsNum(item.unit_price) 
             return res
        },0)
        return total
     },
     REFUNDTOTAL() {
         var total =  this.empties.reduce((res, item)=>{ 
             res += this.$IsNum(item.refund)  * this.$IsNum(item.unit_price) 
             return res
        },0)
        return total
     }  
  },
  methods: {
       emitToParent (action) {
            this.$emit('DialogEvent', action)
    },
    print () {
      // Pass the element id here
      this.$htmlToPaper('PrintMe');
    }
  }
}
</script>

<style>
.print-size {
    font-size: .8em;
    margin: 12px;
}
.print_table {
  width: 100%;
  
}
.print_table td {
  border-bottom: 1px solid #eee;
  padding: 3px;
}
.td-head {
  background: #e5e5e5;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
</style>
