<template>
  <div class="small">
          <income-chart :height="300" :chart-data="CHARTDATA" style="background: #1F222A; padding: 7px; color: #fff;" dark></income-chart>
  </div>
</template>

<script>
  import IncomeChart from '@/plugins/IncomeChart.js'

  export default {
      components: {
        IncomeChart
      },
        data: () => ({
            labels: [],
            kilos:[],
            ticket:[],
            parking:[],
            commission:[],
            service_charge:[],
            trucking_fee:[],
            labor:[],
          datacollection:{},
          gradient: null,
      }),
      props: {
        rawdata: {
          type: Array,
          default: function(){ return []}
        }
      },
    watch: {
      rawdata() {
        this.exportData()
        this.fillData()
        
      }
    },
    computed: {
      CHARTDATA() {
        return this.datacollection
      },
      BGCOLOR() {
        var canvas = document.getElementById('line-chart')
        var gradkilos = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradkilos.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradkilos.addColorStop(1, "rgba(255, 255, 255, 0.24)");

                var gradticket = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradticket.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradticket.addColorStop(1, "rgba(162,69,158)");

        var gradparking = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradparking.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradparking.addColorStop(1, "rgba(132,200,213)");

        var gradlabor= canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradlabor.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradlabor.addColorStop(1, "rgba(1,166,152)");

        var gradpsc = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradpsc.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradpsc.addColorStop(1, "rgba(12,76,138)");

        var gradcomm = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradcomm.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradcomm.addColorStop(1, "rgba(0,121,0)");

        var gradtf = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradtf.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradtf.addColorStop(1, "rgba(0,0,255)");

        return [gradkilos, gradticket, gradparking, gradlabor, gradpsc, gradcomm, gradtf]
      }
    },
    methods: {
      exportData() {
          console.log(this.rawdata)
        this.labels = []
        this.data = []
        for (const item of this.rawdata) {
              this.labels.push(item.month_year)
                this.kilos.push(item.kilos)
                this.ticket.push(item.ticket)
                this.parking.push(item.parking)
                this.commission.push(item.commission)
                this.service_charge.push(item.service_charge)
                this.trucking_fee.push(item.trucking_fee)
                this.labor.push(item.labor)
          }
      
      },
      fillData () {
        
        this.datacollection = {
          labels:this.labels,
          datasets: [
            {
              label:"Kilos",
              backgroundColor: this.BGCOLOR[0],
              borderColor: "#FFF",
              pointBorderColor: "#FFF",
              pointBackgroundColor: "#FFF",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              borderWidth: 0.5,
              fill: true,
              data: this.kilos
            },
            {
              label: "Ticket",
               backgroundColor: this.BGCOLOR[1],
              borderColor: "#F45C51",
              pointBorderColor: "#F45C51",
              pointBackgroundColor: "#F45C51",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              borderWidth: 0.5,
              fill: true,
              data: this.ticket
            },
             {
              label: "Parking",
              backgroundColor: this.BGCOLOR[2],
              borderColor: "#84C8D5",
              pointBorderColor: "#84C8D5",
              pointBackgroundColor: "#84C8D5",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              borderWidth: 0.5,
              fill: true,
              data: this.parking
            },
             {
              label: "Labor",
             backgroundColor: this.BGCOLOR[3],
              borderColor: "#01A698",
              pointBorderColor: "#01A698",
              pointBackgroundColor: "#01A698",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              borderWidth: 0.5,
              fill: true,
              data: this.labor
            },
             {
              label: "SC",
              backgroundColor: this.BGCOLOR[4],
              borderColor: "#0C4C8A",
              pointBorderColor: "#0C4C8A",
              pointBackgroundColor: "#0C4C8A",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              borderWidth: 0.5,
              fill: true,
              data: this.service_charge
            },
            {
              label: "TF",
              backgroundColor: this.BGCOLOR[6],
              borderColor: "#0000FF",
              pointBorderColor: "#0000FF",
              pointBackgroundColor: "#0000FF",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              borderWidth: 0.5,
              fill: true,
              data: this.trucking_fee
            },
            {
              label: "Commission",
              backgroundColor: this.BGCOLOR[5],
              borderColor: "#007900",
              pointBorderColor: "#007900",
              pointBackgroundColor: "#007900",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              borderWidth: 0.5,
              fill: true,
              data: this.commission
            }
          ]
        }
        // setTimeout(()=>{
        //   this.datacollection.datasets[0].backgroundColor = this.BGCOLOR
        //   console.log(this.datacollection.datasets[0].backgroundColor)
        // }, 1000)
        

      
      }
    }
  }
</script>

<style>
  .small {
    height: 300px;
  }
</style>