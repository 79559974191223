<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="320">
    <v-card>
        <v-toolbar dense color="secondary" dark>
           <v-icon left color="primary">mdi-cash-register</v-icon> PAYMENT
             <v-spacer/>
              <v-icon @click="emitToParent('close')">mdi-close</v-icon>
        </v-toolbar>
            
             <v-form
                ref="form"
                class="ma-2"
                v-model="valid"
                lazy-validation
              >
                <v-row dense>
                  <v-col cols="12">
                      <p class="lg-font text-primary text-center">TOTAL: {{$money(total)}}</p>
                        <v-expansion-panels  v-model="panel">
                            <v-expansion-panel>
                              <v-expansion-panel-header @click="payment.type='Cash'"><span><v-icon large left color="primary">mdi-cash</v-icon> CASH</span></v-expansion-panel-header>
                              <v-expansion-panel-content>
                                    <v-text-field 
                                      v-model="payment.cash_amount"
                                      filled
                                      dense
                                      outlined
                                      type="number"
                                      label="Enter Amount"
                                    />
                              </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                              <v-expansion-panel-header @click="payment.type='Credit'"><span><v-icon large left color="primary">mdi-pencil-box</v-icon> CREDIT</span></v-expansion-panel-header>
                              <v-expansion-panel-content>
                                   <p class="text-center">
                                     <v-btn  color="secondary"   @click="payment.type='Credit', validate()"><v-icon left>mdi-check</v-icon> Credit</v-btn>
                                   </p>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header @click="payment.type='Cheque'"><span><v-icon left color="success">mdi-bank</v-icon> CHEQUE</span> </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-text-field 
                                      v-model="payment.bank"
                                      filled
                                      dense
                                      outlined
                                      label="Enter bank name"
                                    />
                                    <v-text-field 
                                      v-model="payment.cheque_no"
                                      filled
                                      dense
                                      outlined
                                      label="Enter cheque #."
                                    />
                                     <v-text-field 
                                      v-model="payment.cheque_amount"
                                      filled
                                      dense
                                      outlined
                                      type="number"
                                      label="Enter Amount"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>
                  </v-col>
                </v-row>
             </v-form>
        <v-row dense no-gutters class="pa-2" v-if="PAIDBTN">
                <v-btn color="warning" @click="emitToParent('close')">CLOSE</v-btn>
          <v-spacer/>
            <v-btn  color="secondary"   @click="validate()"><v-icon left>mdi-check</v-icon> PAID</v-btn>
        </v-row>
    </v-card>
    <va-confirm :show="confirm" cancelbtn="No" okbtn="Complete" text="Complete Payment?" @DialogEvent="cEvent"/>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    total:{
      type:Number,
       default: 0
    }
  },
  data: () => ({
      valid: true,
      confirm: false,
      payment:{},
      panel:[],
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      PAIDBTN(){
          var paid_amount = 0
          if(this.payment.type == 'Cash') {
              paid_amount = this.$IsNum(this.payment.cash_amount)
          } else if(this.payment.type == 'Cheque'){
               paid_amount = this.$IsNum(this.payment.cheque_amount)
          } else {
              this.paid_amount==  this.total
          }
          if(paid_amount == this.total) {
              return true
          }
          return false
      }
  },
  watch: {
    show(v) {
      if(!v){
          this.payment ={type:"Cash"}
          this.panel = []
        }
    },
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
        
           this.confirm = true
        }
      },
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    cEvent(data) {
      this.confirm = false
      if(data == 'ok') {
         this.$emit('DialogEvent', this.payment)
      } 
    },
   
  }
}
</script>
