<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="370">
    <v-card >
      <v-toolbar dense elevation="1" color="primary" dark>Account Details</v-toolbar>
        <v-card-text class="pa-4 subtitle-1"> 
                     {{cashout.items.game}} - {{cashout.items.draw_date}}
                     <v-divider class="mt-2 mt-b" /> 
                            COMM: {{$money(cashout.items.commission)}}
                            <v-divider class="mt-2 mt-b" /> 
                           WINS: {{$money(cashout.items.wins)}}
                           <v-divider class="mt-2 mt-b" /> 
                           <strong>TOTAL:  {{$money(cashout.total)}}</strong>
                          <v-divider class="mt-2 mt-b" /> 
          <v-form autocomplete="off">
            <v-text-field
                  v-model="account.bank"
                  class="lg-font mt-4"
                  style="font-size: 20px; text-align: center;"
                  label="Name of Bank or eWallet"
                
                  hint="ex. GCASH, MAYA, Metro Bank"
                  outlined
                  large
                />
            <v-text-field
                  v-model="account.name"
                  style="font-size: 20px; text-align: center;"
                  label="Account Name"
                  outlined
                  large
                />
                  <v-text-field
                  v-model="account.number"
                  style="font-size: 20px; text-align: center;"
                  label="Acct. Number"
                  outlined
                  large
                />
                
          </v-form>
          <v-divider/>
        </v-card-text>

        <v-card-actions>
                <v-btn color="warning"  text   @click="text ='close', emitToParent('close')">close</v-btn>
            <v-spacer></v-spacer>
            <v-btn large  color="success"  :loading="loading" @click="ValidateSender()">
               OK</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
        sendbtn:"SEND",
         interval: {},
         account:{
          bank:"",name:"", number:""
         },
         loading: false,
         value:0,
         auth: false,
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    cashout: {
      type: Object,
      default:()=> {}
    }
  },
  watch:{
      show(val) {
          if(!val){
            this.text=''
            this.account ={ bank:"",name:"", number:""},
             this.loading = false
        }
      }
  },
  computed:{

  },
  methods: {
    ValidateSender(){
        if(this.account.bank.trim() != "" && this.account.name.trim() != "" && this.account.number.trim() != "") {
          //this.$emit('DialogEvent', this.account)
          this.sendCashout()
          
        } else {
          this.VA_ALERT('error', "Please complete your details")
        }
    },
    sendCashout() {
      this.loading = true
        let  params = this.cashout
        params.bank = this.account.bank
        params.account_number = this.account.number
        params.account_name = this.account.name

      this.$http.post("new_cashout", params).then(response => {
        this.loading = false
        response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
       this.emitToParent ('close')
      }).catch(e => {
        console.log(e.data)
      })

    },
    emitToParent (e) {
      this.$emit('DialogEvent', e)
    }
  }
}
</script>
