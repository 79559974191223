<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="370">
    <v-card color="secondary" dark>
        <v-card-text class="pa-4 subtitle-1">
           <v-text-field
            v-model="text"
            label="Enter value"
            outlined
            dense
           />
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded  @click="text ='close', emitToParent('close')">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn small color="success"  rounded  @click="emitToParent('save')">{{state=='new'?'Add':'Save'}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    state: {
      type: String,
      default: 'new'
    },
    data: {
      type: String,
      default: 'new'
    }
  },
  watch:{
      show(val) {
          if(val){
            this.state == 'new' ? this.text='':this.text=this.data
         }
      }
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', {action: action, text:this.text.trim()})
    }
  }
}
</script>
