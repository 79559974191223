<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="300">
    <v-card color="secondary" dark>
        <v-card-text class="pa-4 subtitle-1">
          <v-form autocomplete="off">
           <v-text-field
            v-model="text"
            class="lg-font"
            style="font-size: 20px; text-align: center;"
            type="number"
            label="Enter amount"
            outlined
            large
           />
          </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small text rounded  @click="text ='close', emitToParent()">close</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  rounded  @click="validateAmount()"> <v-icon left >mdi-plus</v-icon> ADD</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    linetype: {
       type: String,
        default: ""
    } 
  },
  watch:{
      show(val) {
          if(!val){this.text=''}
      }
  },
  methods: {
    validateAmount(){
      if(this.$IsNum(this.text.trim())) {
        if("RBL" == this.linetype) {
          if(this.$IsNum(this.text.trim()) % 2) {
            this.VA_ALERT('error', "Rambol: Amount must be divisible by two!")
            return
          }
        }

        if(this.$IsNum(this.text.trim()) < 1000) {
           this.emitToParent()
        } else {
          this.VA_ALERT('error', "Entered amount reached the limit!")
        }
      } else {
        this.VA_ALERT('error', "Enter Amount!")
      }
    },
    emitToParent () {
      this.$emit('DialogEvent', this.text.trim())
    }
  }
}
</script>
