<template>
  <div class="small">
          <pie-chart :height="300" :chart-data="CHARTDATA" style="padding: 7px;"></pie-chart>
  </div>
</template>

<script>
  import PieChart from '@/plugins/PieChart.js'

  export default {
      components: {
        PieChart
      },
        data: () => ({
            labels: [],
            credit:[],
            cash:[],
          datacollection:{},
          gradient: null,
      }),
      props: {
        rawdata: {
          type: Object,
          default: function(){ return {} }
        }
      },
    watch: {
      rawdata() {
        this.exportData()
        this.fillData()
        
      }
    },
    computed: {
      CHARTDATA() {
        return this.datacollection
      }
    },
    methods: {
      exportData() {
          console.log(this.rawdata)
        this.labels = this.rawdata.labels
        this.data = this.rawdata.data
       
      },
      fillData () {
        
        this.datacollection = {
          labels:this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: ['#F45C51', '#84C8D5', '#01A698', '#0C4C8A', '#269C69']
            }
          ]
        }
       
      }
    }
  }
</script>

<style>
  .small {
    height: 300px;
  }
</style>