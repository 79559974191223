<template>
<v-dialog
    v-model="show"
    persistent
    max-width="100%">
    <v-card>
         <v-toolbar dense class="mb-4" color="primary" dark>
            VIEW SALES
           <v-spacer/>
           <v-icon @click="emitToParent('close')">mdi-close</v-icon>
        </v-toolbar>
        <v-row no-gutters dense class="mt-2 pa-2" >
           
            <v-col cols="12">
                <v-autocomplete 
                  v-model="sales.customer"
                  label="Trade Name"
                  :items="customers"
                  item-text="trade_name"
                  item-value="id"
                  :disabled="true"
                  dense
                  solo
                  hide-details
                />
                <v-text-field
                  class="mt-2"
                  v-model="sales.invoice"
                  label="Invoice Number"
                   :disabled="true"
                  dense
                  solo
                   hide-details
                />
            </v-col>
        </v-row>
        <v-row dense class="pa-2">
             <v-col cols="12" class="pos">
               <v-expansion-panels dense>
                      <v-expansion-panel>
                        <v-expansion-panel-header><small>EMPTIES - DEPOSIT/REFUND</small></v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                              <table class="va-table print_table3 sm-font text-left" cellspacing="0">
                                <tr>
                                    <td class="primary white--text">CODE</td>
                                    <td class="text-center primary white--text">REFUND</td>
                                    <td class="text-center primary white--text">DEPOSIT</td>
                                    <td class="text-right primary white--text">AMOUNT</td>
                                </tr>
                                <tr v-for="(item, i) in sales.empties" :key="i">
                                  <td>{{item.product_code}}</td>
                                  <td>
                                    {{item.refund}}
                                  </td>
                                  <td>
                                       {{item.deposit}}
                                  </td>
                                  <td class="text-right">{{$money(($IsNum(item.deposit) - $IsNum(item.refund)) * $IsNum(item.unit_price)  )}}</td>
                                </tr>
                            </table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                        <v-expansion-panel-header><small>FULL CONTENTS</small></v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                              <table class="va-table print_table3 sm-font text-left" cellspacing="0">
                                <tr>
                                    <td class="primary white--text">CODE</td>
                                    <td class="text-center primary white--text">QTY</td>
                                    <td class="text-center primary white--text">PRICE</td>
                                    <td class="text-right primary white--text">AMOUNT</td>
                                </tr>
                                <tr v-for="(item, index) in sales.fulls" :key="index + 'f'">
                                      <td>{{item.product_code}}</td>
                                      <td class="text-right">{{item.quantity}}</td>
                                      <td class="text-right">{{$money(item.unit_price)}}</td>
                                      <td class="text-right">{{$money($IsNum(item.unit_price) * $IsNum(item.quantity) )}}</td>
                                </tr>
                            </table>
                          
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                     <v-expansion-panel>
                        <v-expansion-panel-header><small>REDEEM EMPTIES</small></v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                              <table class="va-table print_table3 sm-font text-left" cellspacing="0">
                                <tr>
                                    <td class="primary white--text">CODE</td>
                                    <td class="text-center primary white--text">QTY</td>
                                    <td class="text-right primary white--text">AMOUNT</td>
                                </tr>
                                <tr v-for="(item, index) in sales.keeps" :key="index + 'r'">
                                      <td> {{item.code}}</td>
                                      <td class="text-right">{{item.quantity}}</td>
                                      <td class="text-right">{{$money($IsNum(item.unit_price) * $IsNum(item.quantity) )}}</td>
                                </tr>
                            </table>
                         
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                        <v-expansion-panel-header><small>BORROWED EMPTIES</small></v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                              <table class="va-table print_table3 sm-font text-left" cellspacing="0">
                                <tr>
                                    <td class="primary white--text">CODE</td>
                                    <td class="text-center primary white--text">QTY</td>
                                    <td class="text-right primary white--text">AMOUNT</td>
                                </tr>
                                <tr v-for="(item, index) in sales.borroweds" :key="index + 'r'">
                                      <td>{{item.product_code}}</td>
                                      <td class="text-right">{{item.quantity}}</td>
                                      <td class="text-right">{{$money($IsNum(item.unit_price) * $IsNum(item.quantity) )}}</td>
                                </tr>
                            </table>
                           
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header><small>DISCOUNTS</small></v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                              <table class="va-table print_table3 sm-font text-left" cellspacing="0">
                                <tr>
                                    <td class="primary white--text">DESCRIPTIONS</td>
                                    <td class="text-right primary white--text">AMOUNT</td>
                                </tr>
                                <tr v-for="(item, index) in sales.discounts" :key="index + 'f'">
                                      <td>{{item.description}}</td>
                                      <td class="text-right">{{$money(item.amount)}}</td>
                                </tr>
                            </table>
                           
                        </v-expansion-panel-content>
                      </v-expansion-panel>


                </v-expansion-panels>
             </v-col>

              <v-col cols="12">
                  <table class="va-table print_table3 sm-font text-left" cellspacing="0">
                          <tr>
                              <td width="60%">GROSS VALUE SOLD </td>
                              <td class="text-right">{{$money(GROSSTOTAL)}}</td>
                          </tr>
                          <tr>
                              <td>NET DEPOSIT/REFUND</td>
                              <td class="text-right">{{$money(EMTPTIESTOTAL)}}</td>
                          </tr>
                          <tr>
                              <td>NET RETURNED</td>
                              <td class="text-right">{{$money(RETURNBORROWEDTOTAL)}}</td>
                          </tr>
                          <tr>
                              <td>LESS DISCOUNT</td>
                              <td class="text-right">{{$money(DISCOUNTTOTAL)}}</td>
                          </tr>
                           <tr>
                              <td>LESS REDEEMED</td>
                              <td class="text-right">{{$money(REDEEMED_TOTAL)}}</td>
                          </tr>
                           <tr>
                              <td>LESS BORROWED</td>
                              <td class="text-right">{{$money(BORROWEDTOTAL)}}</td>
                          </tr>
                          <tr>
                              <th class="pa-2 lg-font">GRAND TOTAL </th>
                              <th class="text-right pt-a lg-font">{{$money(GRANDTOTAL)}}</th>
                          </tr>
                  </table>
              </v-col>
        </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
         sales:{empties:[], fulls:[], discounts:[], keeps:[], borroweds:[]}
    }),
      props: {
        show: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default:()=>{}
        }
    },
    watch:{
        show(v) {
            if(v) { 
                this.sales = this.item
                this.setData()
            } else {
              this.sales= {empties:[], fulls:[], discounts:[],  keeps:[], borroweds:[]}
            }
        }
    },
    computed:{
       user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      customer_name(){
          var name = this.customers.reduce((res,item)=>{
              if(item.id == this.sales.customer) {
                res = item.trade_name
              }
              return res
          },'')
          return name
      },
      selected_customer(){
          var select = this.$store.state.customers.reduce((res, item)=>{
              if(item.id==this.sales.customer) {
                  res = item
              }
            return res
          },{})
          return select
      },
      customers() {
         return this.$store.state.customers
      },
      products() {
         return this.$store.state.product_items
      },
      canedit() {
          if(this.sales.invoice == undefined || this.sales.invoice == '') {
            return false
          }
          return true
      },
      DISCOUNTTOTAL(){
          var total =  this.sales.discounts.reduce((res, item)=>res +=  this.$IsNum(item.amount),0)
        return total
      },
       GROSSTOTAL() {
        var total =  this.sales.fulls.reduce((res, item)=>res += this.$IsNum(item.quantity)* this.$IsNum(item.unit_price),0)
        return total
      },
      EMTPTIESTOTAL() {
        var total =  this.sales.empties.reduce((res, item)=>res += (this.$IsNum(item.deposit) - this.$IsNum(item.refund)) * this.$IsNum(item.unit_price),0)
        return total
      },
      GRANDTOTAL(){
          return this.GROSSTOTAL + this.EMTPTIESTOTAL + this.RETURNBORROWEDTOTAL  - this.BORROWEDTOTAL - this.REDEEMED_TOTAL - this.DISCOUNTTOTAL
      },
      IntEmpties(){
          var empties = this.sales.empties.reduce((res,item)=>{
              item.refund=this.$IsNum(item.refund)
              item.deposit=this.$IsNum(item.deposit)
              res.push(item)
              return res
          }, [])
          return empties
      },
      REDEEMED_TOTAL() {
          var total =  this.sales.keeps.reduce((res, item)=>res += this.$IsNum(item.quantity)* this.$IsNum(item.unit_price),0)
        return total
      },
      RETURNBORROWEDTOTAL() {
          var total =  this.sales.borroweds.reduce((res, item)=>{
            if(item.type=='Returned') {
              res += this.$IsNum(item.amount)
            }
            return res
          },0)
          return total
      },
      BORROWEDTOTAL() {
          var total =  this.sales.borroweds.reduce((res, item)=>{
            if(item.type=='Borrowed') {
              res += this.$IsNum(item.amount)
            }
            return res
          },0)
          return total
      }
    },
    methods: {
      ...mapMutations(['setLoggedIn']),
       emitToParent (action) {
        this.$emit('DialogEvent',action)
        },
      setData(){
       // this.SET_PRODUCT_ITEMS()
       // this.SET_DRNUMBER()
        this.SET_EMPTIES()
        this.SET_FULLS()
        this.SET_CUSTOMERS()
      }
      
      
    },
  }
</script>