<template>
  <div class="small">
          <line-chart :height="300" :chart-data="CHARTDATA" style="background: #1F222A; padding: 7px; color: #fff;" dark></line-chart>
  </div>
</template>

<script>
  import LineChart from '@/plugins/LineChart.js'

  export default {
      components: {
        LineChart
      },
        data: () => ({
            labels: [],
            credit:[],
            cash:[],
          datacollection:{},
          gradient: null,
      }),
      props: {
        rawdata: {
          type: Array,
          default: function(){ return []}
        }
      },
    watch: {
      rawdata() {
        this.exportData()
        this.fillData()
        
      }
    },
    computed: {
      CHARTDATA() {
        return this.datacollection
      },
      BGCOLOR() {
        var canvas = document.getElementById('line-chart')
        var gradcredit = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradcredit.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradcredit.addColorStop(1, "rgba(132,200,213)");

                var gradcash = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradcash.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradcash.addColorStop(1, "rgba(244,92,81)");


        return [gradcredit, gradcash]
      }
    },
    methods: {
      exportData() {
          console.log(this.rawdata)
        this.labels = []
        this.data = []
        for (const item of this.rawdata) {
              this.labels.push(item.month_year)
                this.credit.push(item.credit)
                this.cash.push(item.cash)
                
          }
      
      },
      fillData () {
        
        this.datacollection = {
          labels:this.labels,
          datasets: [
            {
              label:"Credit Sales",
              backgroundColor: this.BGCOLOR[0],
              borderColor: "#84C8D5",
              pointBorderColor: "#84C8D5",
              pointBackgroundColor: "#84C8D5",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              borderWidth: 0.5,
              fill: false,
              data: this.credit
            },
            {
              label: "Cash Sales",
               backgroundColor: this.BGCOLOR[1],
              borderColor: "#F45C51",
              pointBorderColor: "#F45C51",
              pointBackgroundColor: "#F45C51",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              borderWidth: 0.5,
              fill: false,
              data: this.cash
            }
          ]
        }
       
      }
    }
  }
</script>

<style>
  .small {
    height: 300px;
  }
</style>