<template>
    <v-row no-gutters>
            <v-col md="12" class="mt-10 mb-5">
                    <v-row no-gutters>
                        <v-divider/> <span class="title">MONTHLY SALES</span><v-divider/>
                    </v-row>
            </v-col>
           <v-col md="4">
               <chart-pie :rawdata="PIESUMMARYDATA"/>
           </v-col>
           <v-col md="8">
               <chart-sales :rawdata="SUMMARYDATA"/>
           </v-col>
          <v-col md="12">
             <v-data-table
              :headers="headers"
              dense
              class="va-table pt-5"
              :items="SUMMARYDATA"
              :items-per-page="10000"
              disable-sort
              hide-default-footer
              >
               <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item,index) in items" :key="index">
                              <td>
                                  {{ item.month_year }}
                              </td>
                              <td class="text-right">
                                  {{ $money(item.credit) }}
                              </td>
                              <td class="text-right">
                                  {{ $money(item.cash) }}
                              </td>
                               <td class="text-right">
                                  {{ $money($IsNum(item.cash) + $IsNum(item.credit)) }}
                              </td>
                              
                      </tr>
                  </tbody>
                  <tfoot >
                <tr class="font-weight-bold" >          
                    <td class="text-right" style="border-top: 1px solid #212121;">TOTAL</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">{{ $money(CREDIT) }}</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">{{ $money(CASH) }}</td>
                    <td class="text-right" style="border-top: 1px solid #212121;">
                       {{ $money(CREDIT + CASH) }}
                    </td>
                </tr>
                
            </tfoot>
               </template>
             </v-data-table>
          </v-col>
        </v-row>
</template>

<script>
export default {
//   props: {
//     summarydata: {
//         type: String,
//         default: ""
//     }
//   },
  data: () => ({
      headers:[
          {text: 'Year/Month'},
          {text: 'Credit', align:'right'},
          {text: 'Cash', align:'right'},
          {text: 'Total', align:'right'}
      ]
    }),
computed:{
      CASH(){
          var arr = this.SUMMARYDATA
           let total = arr.reduce((a, o) => a += this.$IsNum(o.cash), 0)
           return total
      },
       CREDIT(){
          var arr = this.SUMMARYDATA
           let total = arr.reduce((a, o) => a += this.$IsNum(o.credit), 0)
           return total
      },
       PIESUMMARYDATA(){
          let piedata = {
              labels: ['Cash', 'Credit'],
              data: [this.CASH, this.CREDIT]
          }
          return piedata
      },
      SUMMARYDATA(){
         return this.$store.state.sales_summary
      }
    },
  methods: {
  }
}
</script>
